import { useMatch } from "@reach/router"
import { navigate } from "gatsby-link"

import {
    GUIDE_TECH_URL,
    QUERY_STRING_PROPS,
    RESOURCES_URL,
} from "~config/constants"
import { BookingInternalSource } from "~graphql/generated/graphql"
import { getCurrentPath } from "~utils/helpers"

import { getUrlQueryParam } from "./url-param-helpers"

export const NAVIGATION_URLS = {
    INTERNAL: {
        HOME: "/",
        LISTING: "/listing",
        OUTFITTERS_BY_STATE: "/outfitters",
        TRIPS: "/trips",
        WISHLISTS: "/wishlists",
        HELP: "/help",
        INBOX: "/inbox",
        ACCOUNT: "/account",
        BOOK: "/book",
        BOOKING: "/booking",
        CHECKOUT_SUMMARY: "/checkout-summary",
        ACCOUNT_PAYMENT_METHODS: "/account/payment-methods",
        ACCOUNT_PAYMENT_HISTORY: "/account/payment-history",
        ACCOUNT_SETTINGS: "/account/settings",
        ACCOUNT_CHANGE_PASSWORD: "/account/change-password",
    },
    EXTERNAL: {
        GUIDE_TECH: GUIDE_TECH_URL,
        ADMIN_DASHBOARD: process.env.GATSBY_DASHBOARD_URL!,
        GUIDE_TECH_URL: GUIDE_TECH_URL,
    },
}

export function navigateToGuidetechPage() {
    return navigate(GUIDE_TECH_URL)
}

export function navigateToTripsPage() {
    void navigate(NAVIGATION_URLS.INTERNAL.TRIPS)
}

export function navigateToAccountPage() {
    void navigate(NAVIGATION_URLS.INTERNAL.ACCOUNT)
}

export function navigateToWishlistsPage() {
    void navigate(NAVIGATION_URLS.INTERNAL.WISHLISTS)
}

export function navigateToHelpPage() {
    void navigate(NAVIGATION_URLS.INTERNAL.HELP)
}

export function getKnowledgeBaseUrl() {
    return `${RESOURCES_URL}sportsman-success`
}

export function navigateToInboxPage() {
    void navigate(NAVIGATION_URLS.INTERNAL.INBOX)
}

export function navigateToCheckoutPage({
    listingId,
    pricingPackageId,
    quoteId,
    quotePackageId,
}: CheckoutNavigationArgs) {
    const route = getCheckoutRoute({
        listingId,
        pricingPackageId,
        quoteId,
        quotePackageId,
    })

    void navigate(route)
}

export function navigateToCheckoutSummaryPage(quoteId: string) {
    const route = getCheckoutSummaryRoute(quoteId)

    void navigate(route)
}

export function navigateToBookPage(quoteId: string) {
    const route = getBookRoute(quoteId)

    void navigate(route)
}

export function navigateToBookingPage(bookingId: string) {
    const route = getBookingRoute(bookingId)

    void navigate(route)
}

export function getListingRoute(id?: string) {
    return `${NAVIGATION_URLS.INTERNAL.LISTING}/${id}`
}

export function getOutfitterRoute(slug: string) {
    return `/${slug}`
}

export function useIsAccountRoute() {
    return useMatch(`${NAVIGATION_URLS.INTERNAL.ACCOUNT}/*`)
}

export function navigateBackOrHome() {
    void navigate(window.history.state?.back ?? NAVIGATION_URLS.INTERNAL.HOME)
}

function getCheckoutRoute({
    listingId,
    pricingPackageId,
    quoteId,
    quotePackageId,
}: CheckoutNavigationArgs) {
    const baseRoute = `${NAVIGATION_URLS.INTERNAL.LISTING}/${listingId}/checkout`
    const pricingPackageParam = getSearchParam(
        QUERY_STRING_PROPS.PACKAGE_ID,
        pricingPackageId
    )
    const quoteParam = getSearchParam(QUERY_STRING_PROPS.QUOTE_ID, quoteId)
    const quotePackageParam = getSearchParam(
        QUERY_STRING_PROPS.QUOTE_PACKAGE_ID,
        quotePackageId
    )

    return getRouteWithInternalSourceQueryStringParams({
        baseUrl: baseRoute,
        initialParams: [pricingPackageParam, quoteParam, quotePackageParam],
    })
}

export function getCheckoutSummaryRoute(quoteId: string) {
    return getRouteWithInternalSourceQueryStringParams({
        baseUrl: NAVIGATION_URLS.INTERNAL.CHECKOUT_SUMMARY,
        initialParams: [`${QUERY_STRING_PROPS.QUOTE_ID}=${quoteId}`],
    })
}

export function getWishlistRoute(wishlistId: string) {
    return `${NAVIGATION_URLS.INTERNAL.WISHLISTS}/${wishlistId}`
}

function getBookRoute(quoteId: string) {
    return getRouteWithInternalSourceQueryStringParams({
        baseUrl: NAVIGATION_URLS.INTERNAL.BOOK,
        initialParams: [`${QUERY_STRING_PROPS.QUOTE_ID}=${quoteId}`],
    })
}

function getBookingRoute(bookingId: string) {
    return `${NAVIGATION_URLS.INTERNAL.BOOKING}/${bookingId}`
}

function getRouteWithInternalSourceQueryStringParams({
    baseUrl,
    initialParams = [],
}: {
    baseUrl: string
    initialParams?: string[]
}) {
    const internalSourceParams = getInternalSourceParams()
    const params = [...initialParams, ...internalSourceParams]
        .filter(Boolean)
        .join("&")
    const query = params ? `?${params}` : ""
    return `${baseUrl}${query}`
}

export function navigateToActivityModal(activityId: string) {
    const currentPath = getCurrentPath()

    const activityUrl = getRouteWithInternalSourceQueryStringParams({
        baseUrl: currentPath,
        initialParams: [`${QUERY_STRING_PROPS.ACTIVITY_ID}=${activityId}`],
    })

    return navigate(activityUrl)
}

export function isBookingInvitation() {
    const internalSourceParam = getSearchParamFromUrl(
        QUERY_STRING_PROPS.INTERNAL_SOURCE
    )
    return internalSourceParam === BookingInternalSource.SendQuote
}

function getInternalSourceParams() {
    const internalSourceParam = getSearchParamFromUrl(
        QUERY_STRING_PROPS.INTERNAL_SOURCE
    )
    const internalSourceEntityIdParam = getSearchParamFromUrl(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_ID
    )
    const internalSourceEntityTypeParam = getSearchParamFromUrl(
        QUERY_STRING_PROPS.INTERNAL_SOURCE_ENTITY_TYPE
    )

    return [
        internalSourceParam,
        internalSourceEntityIdParam,
        internalSourceEntityTypeParam,
    ].filter(Boolean)
}

function getSearchParam(name: string, value?: string | null) {
    if (!value) return ""

    return `${name}=${value}`
}

function getSearchParamFromUrl(name: string) {
    return getSearchParam(name, getUrlQueryParam(name))
}

export function getManageListingPageUrl(listingId: string) {
    return `${NAVIGATION_URLS.EXTERNAL.ADMIN_DASHBOARD}manage-listings/${listingId}`
}

interface CheckoutNavigationArgs {
    listingId: string
    pricingPackageId?: string | null
    quoteId?: string | null
    quotePackageId?: string | null
}
