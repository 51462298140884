import type { PropsWithChildren } from "react"
import React, { createContext, useContext, useEffect, useState } from "react"
import { BaseProvider } from "baseui"
import usePrefersColorScheme from "use-prefers-color-scheme"

import { LightTheme, DarkTheme } from "~config/theme"

enum ColorMode {
    LIGHT = "light",
    DARK = "dark",
    NO_PREFERENCE = "no-preference",
}

interface IThemeContext {
    colorMode: ColorMode
    isDarkMode: boolean
}

const ThemeContext = createContext<IThemeContext>({
    colorMode: ColorMode.LIGHT,
    isDarkMode: false,
})

export function ThemeProvider({ children }: Readonly<PropsWithChildren>) {
    const preferedColorMode = usePrefersColorScheme() as ColorMode
    const isDarkMode = preferedColorMode === ColorMode.DARK

    // Keep theme in state so it rehydrates properly
    const [theme, setTheme] = useState(LightTheme)

    useEffect(() => {
        const activeTheme = isDarkMode ? DarkTheme : LightTheme
        setTheme(activeTheme)
    }, [isDarkMode])

    const value = {
        colorMode: preferedColorMode,
        theme: preferedColorMode,
        isDarkMode,
    }

    return (
        <BaseProvider theme={theme}>
            <ThemeContext.Provider value={value}>
                {children}
            </ThemeContext.Provider>
        </BaseProvider>
    )
}

export const useThemeContext = () => {
    return useContext(ThemeContext)
}
