import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"

import ResponsiveBlock from "~components/shared/responsive-block"
import HiddenOnMobileContainer from "~components/shared/wrappers/hidden-on-mobile-container"
import HiddenOnDesktopContainer from "~components/shared/wrappers/hidden-on-desktop-container"
import TextLogo from "~components/shared/logo/text-logo"
import HeaderLoginButton from "~components/shared/header/header-login-button"
import HeaderProfileButton from "~components/shared/header/header-profile-button"
import OutfitterCallToActionButton from "~components/shared/header/outfitter-call-to-action-button"
import HeaderSearchBar from "~components/shared/header/header-search-bar"
import { useResponsive } from "~config/responsive-provider"
import { useColorModeValue } from "~utils/use-color-mode"
import { useAuth } from "~utils/auth-hooks"
import { PAGE_HEADER_HEIGHT } from "~config/constants"
import { useTheme } from "~config/theme"
import {
    SUGGESTIONS_POPOVER_ID,
    CALENDAR_POPOVER_ID,
} from "~components/shared/search-input/desktop-search-input"
import { useTabletMediaQueries } from "~utils/use-media-queries"
import CourierInbox from "~components/shared/courier-inbox/courier-inbox"

export default function Header() {
    const { isLoading, isAuthenticated } = useAuth()
    const { collapsed, setCollapsed, headerRef } = useHeaderCollapse()
    const styles = useStyles({ isCollapsed: collapsed })

    const shouldRender = useShouldRenderHeader()

    if (!shouldRender) return null

    return (
        <>
            <div className={styles.overlay} />
            <ResponsiveBlock className={styles.root}>
                {/* MOBILE */}
                <HiddenOnDesktopContainer>
                    <div className={styles.headerContent}>
                        <HeaderSearchBar clickHandler={() => {}} />
                    </div>
                </HiddenOnDesktopContainer>

                {/* DESKTOP */}
                <HiddenOnMobileContainer>
                    <div ref={headerRef} className={styles.headerContent}>
                        <TextLogo />
                        <div className={styles.searchBarContainer}>
                            <HeaderSearchBar
                                isCollapsed={collapsed}
                                clickHandler={() => setCollapsed(false)}
                            />
                        </div>
                        <div className={styles.headerRight}>
                            <div>
                                {collapsed && <OutfitterCallToActionButton />}
                            </div>
                            <CourierInbox />
                            {(isLoading || isAuthenticated) && (
                                <HeaderProfileButton />
                            )}
                            {!isLoading && !isAuthenticated && (
                                <HeaderLoginButton />
                            )}
                        </div>
                    </div>
                </HiddenOnMobileContainer>
            </ResponsiveBlock>
        </>
    )
}

function useShouldRenderHeader() {
    const { pathname } = useLocation()
    const { isTabletOrLarger } = useTabletMediaQueries()

    return isTabletOrLarger || pathname === "/" || pathname.includes("/search")
}

function useHeaderCollapse() {
    const headerRef = useRef<HTMLDivElement | null>(null)
    const [collapsed, setCollapsed] = useState(true)
    const { windowScroll } = useResponsive()
    const { pathname } = useLocation()

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const clickedNode = event.target as Node
            const searchInputPopovers = document.querySelectorAll(
                `[id="${SUGGESTIONS_POPOVER_ID}"], [id="${CALENDAR_POPOVER_ID}"]`
            )

            const isClickedInsideHeader =
                headerRef.current?.contains(clickedNode)

            searchInputPopovers.forEach((popoverElement) => {
                if (
                    popoverElement.contains(clickedNode) ||
                    isClickedInsideHeader
                ) {
                    return
                } // Do nothing if clicked inside search input popover or header

                // Collapse search input if clicked outside search input popover or header
                setCollapsed(true)
            })
        }
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [headerRef, windowScroll.y])

    useEffect(() => {
        if (windowScroll.y > 30) {
            setCollapsed(true)
        }
    }, [windowScroll.y])

    // Collapse search input when navigating to new page
    useEffect(() => {
        setCollapsed(true)
    }, [pathname])

    return { collapsed, setCollapsed, headerRef }
}

function useStyles({ isCollapsed }: { isCollapsed: boolean }) {
    const { css, theme } = useTheme()
    const { pathname } = useLocation()
    const { windowScroll } = useResponsive()
    const scrolled = windowScroll.y > 30
    const bgColor = useColorModeValue(
        theme.colors.backgroundPrimary,
        "rgba(20, 20, 20, .75)"
    )

    const isHomePage = pathname === "/"
    const mainBackgroundColor =
        scrolled || !isCollapsed || !isHomePage ? bgColor : "transparent"

    return {
        root: css({
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            boxSizing: "border-box",
            boxShadow: scrolled ? theme.lighting.shadow500 : "none",
            transition: "all 0.2s ease-in-out",
            backgroundColor: theme.colors.backgroundPrimary,
            zIndex: 200,
            [theme.mediaQuery.medium]: {
                boxShadow: "none",
                backgroundColor: mainBackgroundColor,
                backdropFilter: scrolled ? "blur(24px)" : "blur(0px)",
                WebkitBackdropFilter: scrolled ? "blur(24px)" : "blue(0px)",
            },
        }),
        headerContent: css({
            width: "100%",
            height: "auto",
            [theme.mediaQuery.medium]: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: PAGE_HEADER_HEIGHT,
            },
        }),
        headerRight: css({
            display: "flex",
            minWidth: "200px",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
        }),
        overlay: css({
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            pointerEvents: "none",
            backgroundColor: isCollapsed
                ? "transparent"
                : theme.colors.backgroundOverlayDark,
            visibility: isCollapsed ? "hidden" : "visible",
            transition: "all 0.2s ease-in-out",
        }),
        searchBarContainer: css({
            // This is hacky but gets the job done for now
            // After we added the outfitter button, it got pushed to the left
            // which looks weird on big screens.
            // We hide the button when the search bar is expanded, so only do this if collapsed
            [theme.mediaQuery.desktop]: {
                marginLeft: isCollapsed ? "170px" : undefined,
            },
        }),
    }
}
