import React, { useRef } from "react"

import useIsVisible from "~utils/use-is-visible"

interface Props {
    readonly children: React.ReactNode
}

export default function LazyContainer({ children }: Props) {
    const canaryRef = useRef<HTMLDivElement>(null)
    const isVisible = useIsVisible(canaryRef)

    return (
        <>
            <div ref={canaryRef}></div>
            {isVisible && children}
        </>
    )
}
