import type { PropsWithChildren } from "react"
import React from "react"

import ResponsiveBlock from "~components/shared/responsive-block"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import { useTheme } from "~config/theme"
import { TEST_IDS } from "~config/test-ids"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly noPadding?: boolean
    readonly onScroll?: () => void
}

export default function GenericCollectionList({
    label,
    buttonLabel,
    buttonUrl,
    noPadding,
    onScroll,
    children,
}: Props) {
    const styles = useStyles()

    return (
        <>
            <ResponsiveBlock noPadding={noPadding}>
                {label && <PageSectionHeading label={label} />}
                <div
                    data-testid={TEST_IDS.GENERIC_COLLECTION_LIST}
                    className={styles.container}
                    onScroll={onScroll}
                >
                    {children}
                </div>
            </ResponsiveBlock>

            <div className={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </div>
        </>
    )
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        buttonContainer: css({
            textAlign: "center",
            marginTop: theme.sizing.scale800,
        }),
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: theme.sizing.scale800,
            maxHeight: "60vh",
            overflowY: "scroll",
        }),
    }
}
