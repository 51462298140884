import React, { useRef, useState } from "react"
import type { Settings } from "react-slick"
import Slider from "react-slick"

import {
    useTabletMediaQueries,
    useMobileMediaQueries,
} from "~utils/use-media-queries"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import { TEST_IDS } from "~config/test-ids"
import { useTheme } from "~config/theme"

import ResponsiveSliderButtons from "./responsive-slider-buttons"
import "./responsive-slider.css"

const SLIDES_TO_SHOW_MOBILE = 1
const SLIDES_TO_SHOW_TABLET = 2
const SLIDES_TO_SHOW_DEFAULT = 4

interface Props {
    readonly items: React.JSX.Element[] | React.ReactNode[]
    readonly title?: string
    readonly shouldCenterMobile?: boolean
    readonly onSwipe?: () => void
    readonly hideCount?: boolean
}

export default function ResponsiveSlider({
    items,
    title,
    shouldCenterMobile,
    onSwipe,
    hideCount,
}: Props) {
    const styles = useStyles()
    const sliderRef = useRef<Slider>(null)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const itemCount = items.length
    const { slideStateLabel, shouldShowButtons } = useSliderHelper({
        itemCount,
        currentPage,
    })

    const settings = useSliderSettings({
        itemCount,
        currentPage,
        shouldCenterMobile,
    })

    return (
        <div className={styles.root} data-testid={TEST_IDS.RESONSIVE_SLIDER}>
            <div className={styles.header}>
                {title && <PageSectionHeading label={title} />}
                {shouldShowButtons && (
                    <div className={styles.headerRight}>
                        {!hideCount && (
                            <span className={styles.slideStatus}>
                                {slideStateLabel}
                            </span>
                        )}
                        <ResponsiveSliderButtons
                            onLeftClick={() => sliderRef.current?.slickPrev()}
                            onRightClick={() => sliderRef.current?.slickNext()}
                        />
                    </div>
                )}
            </div>
            <div className={styles.scrollView}>
                <Slider
                    {...settings}
                    className={styles.scrollView}
                    ref={sliderRef}
                    afterChange={(slide) => setCurrentPage(slide)}
                    swipeEvent={onSwipe}
                >
                    {items.map((item, index) => (
                        <div key={index} className={styles.itemWrapper}>
                            {item}
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

function useSliderHelper({
    itemCount,
    currentPage,
}: {
    itemCount: number
    currentPage: number
}) {
    const slidesToShow = useSlidesToShow()
    const actualSlidesToShow = Math.min(slidesToShow, itemCount)
    const shouldBeInfinite = itemCount > slidesToShow

    if (!itemCount) {
        return {
            slideStateLabel: "",
            actualSlidesToShow,
            shouldBeInfinite,
        }
    }

    const currentPart =
        calculateSlideStateLabelPart(currentPage, actualSlidesToShow) + 1
    const totalPart = calculateSlideStateLabelPart(
        itemCount,
        actualSlidesToShow
    )
    // If we only have one page, don't show label or buttons
    const shouldShowButtons = totalPart !== 1

    const labelParts = shouldShowButtons
        ? `${currentPart} / ${totalPart}`
        : null
    return {
        slideStateLabel: labelParts,
        actualSlidesToShow,
        shouldShowButtons,
        shouldBeInfinite,
    }
}

function calculateSlideStateLabelPart(
    number: number,
    actualSlidesToShow: number
) {
    return Math.ceil(number / actualSlidesToShow)
}

function useMediaQueries() {
    const { isTabletOnly } = useTabletMediaQueries()
    const { isMobileOnly } = useMobileMediaQueries()

    return { isTabletOnly, isMobileOnly }
}

function useSlidesToShow() {
    const { isMobileOnly, isTabletOnly } = useMediaQueries()

    if (isMobileOnly) return SLIDES_TO_SHOW_MOBILE
    if (isTabletOnly) return SLIDES_TO_SHOW_TABLET
    return SLIDES_TO_SHOW_DEFAULT
}

function useStyles() {
    const { css, theme } = useTheme()

    return {
        root: css({
            paddingLeft: "0px",
            paddingRight: "0px",
            marginBottom: theme.sizing.scale1000,
            color: theme.colors.contentPrimary,
        }),
        header: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
        scrollView: css({
            width: "100%",
            position: "relative",
            marginTop: "0",
            marginBottom: "0",
        }),
        headerRight: css({
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            [theme.mediaQuery.small]: {
                alignItems: "center",
            },
        }),
        slideStatus: css({
            fontSize: "15px",
            fontWeight: 700,
            whiteSpace: "nowrap",
            color: theme.colors.contentTertiary,
            marginLeft: theme.sizing.scale600,
            [theme.mediaQuery.small]: {
                marginRight: theme.sizing.scale600,
            },
        }),
        link: css({
            textDecoration: "none",
        }),
        itemWrapper: css({
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100% !important",
        }),
        buttonGroup: css({
            width: "100% !important",
        }),
    }
}

function useSliderSettings({
    itemCount,
    currentPage,
    shouldCenterMobile = true,
}: {
    itemCount: number
    currentPage: number
    shouldCenterMobile?: boolean
}): Settings {
    const { actualSlidesToShow, shouldBeInfinite } = useSliderHelper({
        itemCount,
        currentPage,
    })
    const { isMobileOnly, isTabletOnly } = useMediaQueries()

    return {
        dots: false,
        draggable: isMobileOnly || isTabletOnly,
        centerMode: isMobileOnly && shouldCenterMobile,
        infinite: shouldBeInfinite,
        arrows: false,
        speed: 500,
        slidesToShow: actualSlidesToShow,
        slidesToScroll: actualSlidesToShow,
        initialSlide: 0,
        lazyLoad: "ondemand",
    }
}
