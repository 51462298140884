import React from "react"
import { Link } from "gatsby-link"
import { useStyletron } from "baseui"

import TextLogoDark from "~images/logos/mb-logo-dark.svg"
import TextLogoLight from "~images/logos/mb-logo.svg"
import COPY from "~config/copy-constants"
import { useThemeContext } from "~config/theme-provider"

interface Props {
    readonly isCentered?: boolean
}

export default function TextLogo({ isCentered }: Props) {
    const { isDarkMode } = useThemeContext()
    const styles = useStyles({ isCentered })
    const logoSrc = isDarkMode ? TextLogoDark : TextLogoLight

    return (
        <Link to="/" className={styles.link}>
            <img src={logoSrc} width={200} alt={COPY.MALLARD_BAY_LOGO} />
        </Link>
    )
}

function useStyles({ isCentered }: { isCentered?: boolean }) {
    const [css] = useStyletron()

    return {
        link: css({
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            justifyContent: isCentered ? "center" : "flex-start",
        }),
    }
}
