import React from "react"
import ContentLoader from "react-content-loader"
import { useStyletron } from "baseui"

import { useThemeContext } from "~config/theme-provider"
import { TEST_IDS } from "~config/test-ids"

interface LoadingSkeletonProps {
    readonly dark?: boolean
    readonly circle?: boolean
    readonly radius?: string
    readonly width?: string
    readonly height?: string
    // Margin props
    readonly ml?: string
    readonly mr?: string
    readonly mb?: string
    readonly mt?: string
}

export default function LoadingSkeleton({
    dark = false,
    circle = false,
    radius: radiusOverride = "2px",
    width = "100%",
    height = "100%",
    ml,
    mr,
    mb,
    mt,
}: LoadingSkeletonProps) {
    const { isDarkMode } = useThemeContext()
    const radius = circle ? "50%" : radiusOverride
    const styles = useStyles({ width, height, ml, mr, mb, mt, radius })
    const shouldUseDark = isDarkMode || dark

    return (
        <ContentLoader
            data-testid={TEST_IDS.LOADING_SKELETON}
            className={styles.contentLoader}
            speed={1.5}
            backgroundColor={shouldUseDark ? "#1F1F1F" : "#EEEEEE"}
            foregroundColor={shouldUseDark ? "#141414" : "#F6F6F6"}
        >
            <rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
    )
}

function useStyles({
    width,
    height,
    ml,
    mr,
    mb,
    mt,
    radius,
}: {
    width: string
    height: string
    ml?: string
    mr?: string
    mb?: string
    mt?: string
    radius: string
}) {
    const [css] = useStyletron()

    return {
        contentLoader: css({
            width,
            height,
            marginLeft: ml,
            marginRight: mr,
            marginTop: mt,
            marginBottom: mb,
            borderRadius: radius,
        }),
    }
}
